<template>
  <v-container class="general list">
    <page-title :component="'MessageListPage'">
      <template v-slot:actions>
        <LanguageSwitcher />
        <VideoTutorial
          :component="'MessageListPage'"
          :title="$t('messages-tutorial-title')"
          class="ml-3"
        />
      </template>
    </page-title>

    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !messages.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("group_message_list_page.nothing_created") }}
        </v-flex>
      </v-layout>
    </transition>

    <template v-if="messages && messages.length">
      <v-layout class="mb-4 px-4">
        <v-flex xs5>
          {{ $t("group_message_list_page.table_header_name") }}
        </v-flex>
        <v-flex xs3>
          {{ $t("group_message_list_page.table_header_scheduled_time") }}
        </v-flex>
        <v-flex xs3>
          <v-layout>
            <v-flex xs6 class="text-center">
              <TranslatableTooltip
                :content="
                  $t('group_message_list_page.table_header_status_tooltip')
                "
                center
              >
                {{ $t("group_message_list_page.table_header_status") }}
              </TranslatableTooltip>
            </v-flex>
            <v-flex xs6 class="text-center">
              <TranslatableTooltip
                :content="
                  $t('group_message_list_page.table_header_messages_tooltip')
                "
                center
              >
                {{ $t("group_message_list_page.table_header_messages") }}
              </TranslatableTooltip>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs1></v-flex>
      </v-layout>

      <v-divider></v-divider>

      <div
        v-for="(message, i) in messages"
        :key="`message-${i}`"
        :style="{ width: '100%' }"
      >
        <v-layout class="row no-wrap align-center pa-4">
          <v-flex xs5 class="pr-2">
            <v-layout class="align-center">
              <div class="text-center mr-4 message-type-icon">
                <font-awesome-icon
                  :icon="messageTypeIcon(message.type)"
                  class="grey--text"
                ></font-awesome-icon>
              </div>
              <a
                class="sw-h5 font-weight-light text-ellipsis"
                @click.stop="editMessageTemplate(message.id)"
                >{{ message.name }}</a
              >
            </v-layout>
          </v-flex>
          <v-flex
            xs3
            :class="{ 'grey--text': messageStatusTime(message) !== 'current' }"
          >
            <div v-if="message.send_time_start">
              {{ $tc("from", 1) }}:
              <span class="pl-2">{{
                formatDate(message.send_time_start)
              }}</span>
            </div>
            <div>
              {{ $tc("to", 1) }}:
              <span class="pl-2">{{ formatDate(message.send_time_end) }}</span>
            </div>
          </v-flex>
          <v-flex xs3>
            <v-layout class="align-center">
              <v-flex xs6 class="text-center">
                <font-awesome-icon
                  slot="activator"
                  :icon="['fas', 'circle']"
                  :class="[
                    message.active ? 'status-active' : 'status-inactive',
                  ]"
                />
              </v-flex>
              <v-flex xs6 class="text-center">
                <v-chip
                  :class="'cursor-pointer ma-2'"
                  @click="
                    $router.push({
                      name: 'group_generated_messages',
                      query: { template_id: message.id },
                    })
                  "
                >
                  <div class="text-center message-count">
                    {{ message.message_count }}
                  </div>
                </v-chip>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs1 class="text-right">
            <v-btn
              icon
              class="ma-0"
              :loading="isLoading"
              @click="deleteMessageTemplate(message)"
            >
              <font-awesome-icon class="sw-accent" icon="trash" />
            </v-btn>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </div>
    </template>

    <div v-if="totalPages > 1" class="text-center mt-4">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        @input="listMessageTemplates"
      ></v-pagination>
    </div>

    <app-speed-dial
      v-model="fab"
      :hint="$t('group_message_template_list_page.speed_dial_hint')"
    >
      <template slot="items">
        <div class="px-3 py-2 speed-dial-items">
          <div
            v-for="(type, i) in messageTypes"
            :key="`type-${i}`"
            class="py-2 text-right caption speed-dial-item"
          >
            <span class="mr-3">{{ $t(`new-${type}`) }}</span>
            <v-avatar
              size="36"
              color="white"
              class="elevation-2 cursor-pointer"
              @click="createMessageTemplate(type)"
            >
              <font-awesome-icon
                :icon="messageTypeIcon(type)"
                style="font-size: 19px"
                class="sw-accent"
              />
            </v-avatar>
          </div>
        </div>
      </template>
    </app-speed-dial>

    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  data: () => ({
    isLoading: false,
    fab: false,
    showTooltip: true,
    messageTypes: [],
    messages: [],
    dateFormat: "MMMM DD, YYYY H:mm",
    page: 1,
    totalPages: 0,
    totalVisible: 5,
    perPage: 16,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
    activeGroup() {
      return this.$store.getters.activeGroup;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  components: {
    LanguageSwitcher,
  },
  watch: {
    appContentLanguage: {
      handler() {
        this.listMessageTemplates();
      },
    },
  },
  mounted() {
    this.getMessageTypes();

    this.$nextTick(() => {
      this.page = this.currentPage;
      this.listMessageTemplates();
    });
  },
  methods: {
    async getMessageTypes() {
      try {
        const response = await this.$api.settings.public("group.message_types");

        if (!response || !response.data.data.value) return;

        this.messageTypes = response.data.data.value.split(",");
      } catch (error) {}
    },
    messageTypeIcon(type) {
      if (type === "email") return "envelope";
      if (type === "sms") return "sms";
      if (type === "push") return "mobile";
    },
    formatDate(date) {
      if (date) {
        return moment.tz(date, this.userTimezone).format(this.dateFormat);
      } else {
        return this.$t("messageTemplateNoTimeSelected");
      }
    },
    query() {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, { page: this.page }),
        })
        .catch(() => {});
    },
    createMessageTemplate(type) {
      this.$router.push(
        { name: "group_message_templates_create", query: { type: type } },
        () => {},
      );
    },
    editMessageTemplate(id) {
      this.$router.push(
        {
          name: "group_message_templates_edit",
          params: {
            message_id: id,
            group_plugin_id: this.groupPluginId,
          },
        },
        () => {},
      );
    },
    listMessageTemplates() {
      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          lang: this.appContentLanguage,
          page: this.page,
          per_page: this.perPage,
          sort: "id:desc",
        },
      ];

      this.isLoading = true;

      this.$api.groupMessageTemplates.list(...params).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.messages = response.data.data;
          this.totalPages = response.data.pagination.total_pages;
          this.query();
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async deleteMessageTemplate(model) {
      if (!model) return;
      if (!model.id) return;

      const confirmed = await this.$refs.confirmationDialog.open(
        this.$t("messageDeleteConfirmationTextLine", {
          name: `${model.name}`,
        }),
        this.$t("no"),
        this.$t("yes"),
      );

      if (!confirmed) return;

      const params = [this.groupId, model.id];

      this.isLoading = true;

      this.$api.groupMessageTemplates.delete(...params).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.$store.dispatch("addNotification", {
            message: this.$t("messageDeleteSuccessTextLine", {
              name: `${model.name}`,
            }),
          });

          this.page = 1;
          this.listMessageTemplates();
        },
        (error) => {
          this.isLoading = false;
          this.errorMessageShow(error);
        },
      );
    },
    messageStatusTime(message) {
      if (
        Boolean(message.send_time_start) &&
        new Date(message.send_time_start) > new Date()
      ) {
        return "upcoming";
      }

      if (
        Boolean(message.send_time_end) &&
        new Date(message.send_time_end) < new Date()
      ) {
        return "past";
      }

      return "current";
    },
  },
};
</script>

<style scoped lang="scss">
.message-type-icon {
  min-width: 29px;
  font-size: 29px;
}
.message-count {
  min-width: 44px;
}
.status {
  &-active {
    color: #2ca8a4;
  }
  &-inactive {
    color: #e799af;
  }
}

.speed-dial-items {
  border: 1px solid $accent;
  background: #fff;
  margin-right: -16px;
  margin-bottom: 16px;
  border-radius: 8px;

  .speed-dial-item {
    white-space: nowrap;

    .item-title {
      white-space: nowrap;
    }
  }
}
</style>
